<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper deal-detail-page">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header
            :title="title"
            :breadcrumb="breadcrumb"
            :copy-btn="true"
            :copy-content="deal.lead_id"
          />
          <!-- /Page Header -->

          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="deal-type">
                    <h5>Тип сделки</h5>
                    <span class="badge badge-type">Лизинг</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="deal-status">
                    <p>
                      Дата создания:
                      {{ formatDate(deal.сreated_at) }}
                    </p>
                    <div
                      class="dropdown mb-0"
                      :class="deal.status === 'rejected' ? 'dropdown-gray' : ''"
                    >
                      <a
                        href="#"
                        class="text-white py-1 px-2 d-inline-flex align-items-center gap-2"
                        :data-bs-toggle="
                          deal.status !== 'finished' &&
                          deal.status !== 'rejected'
                            ? 'dropdown'
                            : ''
                        "
                        aria-expanded="false"
                      >
                        {{
                          deal.status === "accepted" || deal.status === "new"
                            ? "На одобрении"
                            : deal.status === "reserved"
                            ? "Забронировано"
                            : deal.status === "finished"
                            ? "Реализовано"
                            : deal.status === "rejected"
                            ? "Отклонено"
                            : ""
                        }}
                        <svg
                          v-if="
                            deal.status !== 'finished' &&
                            deal.status !== 'rejected'
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="7"
                          viewBox="0 0 13 7"
                          fill="none"
                        >
                          <path
                            d="M1.36426 1L6.86426 6L12.3643 1"
                            stroke="white"
                            stroke-linecap="ceil"
                            stroke-linejoin="ceil"
                          />
                        </svg>
                      </a>
                      <div
                        v-if="
                          deal.status !== 'finished' &&
                          deal.status !== 'rejected'
                        "
                        class="dropdown-menu dropdown-menu-right"
                      >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="handleFinish"
                          ><span>Реализовано</span></a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_campaign"
                          ><span>Отклонено</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row g-2">
            <div class="col-md-6">
              <div class="card mb-2">
                <div class="card-body">
                  <p class="card-info-title">{{ deal.client?.name }}</p>
                  <div class="card-info border border-1 ceiled-2">
                    <div class="card-body">
                      <div class="card-info-item-group">
                        <div class="card-info-item">
                          <p class="card-info-item-title">ИНН</p>
                          <p class="card-info-item-value">
                            {{ deal.client_inn || deal.client?.inn }}
                          </p>
                        </div>
                        <div class="card-info-item">
                          <p class="card-info-item-title">Контактное лицо</p>
                          <p class="card-info-item-value">
                            {{ deal.name_of_client }}
                          </p>
                        </div>
                      </div>
                      <div class="card-info-item-group">
                        <div class="card-info-item">
                          <p class="card-info-item-title">Email</p>
                          <p class="card-info-item-value">{{ deal.email }}</p>
                        </div>
                        <div class="card-info-item">
                          <p class="card-info-item-title">Телефон</p>
                          <p class="card-info-item-value">
                            {{ deal.phone }}
                          </p>
                        </div>
                      </div>
                      <div class="card-info-item-group">
                        <div class="card-info-item col-span-2">
                          <p class="card-info-item-title">Адрес компании</p>
                          <p class="card-info-item-value">
                            {{ deal.client?.fact_address }}
                          </p>
                        </div>
                      </div>
                      <div class="card-info-item-group">
                        <div class="card-info-item col-span-2">
                          <p class="card-info-item-title">Менеджер</p>
                          <p class="card-info-item-value">
                            {{ deal.doc_manager?.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-body">
                  <p class="card-info-title">Детали сделки</p>

                  <div class="border border-1 ceiled-2 rounded-1">
                    <div class="card-body">
                      <div class="row g-3 mb-4">
                        <div class="col-12 col-md-6">
                          <label for="deal-type-input" class="form-label"
                            >Тип объекта</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-type-input"
                            placeholder="Тип объекта"
                            aria-label="Тип объекта"
                            aria-describedby="deal-type-input"
                            disabled
                            v-model="leadType"
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label for="deal-mark-input" class="form-label"
                            >Марка</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-mark-input"
                            placeholder="Марка"
                            aria-label="Марка"
                            aria-describedby="deal-mark-input"
                            v-model="leadBrand"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="row g-3 mb-4">
                        <div class="col-12 col-md-6">
                          <label for="deal-model-input" class="form-label"
                            >Модель</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-model-input"
                            placeholder="Модель"
                            aria-label="Модель"
                            aria-describedby="deal-model-input"
                            v-model="leadModel"
                            disabled
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label for="deal-purchase-input" class="form-label"
                            >Способ покупки</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-purchase-input"
                            placeholder="Способ покупки"
                            aria-label="Способ покупки"
                            aria-describedby="deal-purchase-input"
                            v-model="leadPurchaseMethod"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="row g-3 mb-4">
                        <div class="col-12 col-md-6">
                          <label for="deal-year-input" class="form-label"
                            >Год выпуска</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-year-input"
                            placeholder="Год выпуска"
                            aria-label="Год выпуска"
                            aria-describedby="deal-year-input"
                            v-model="leadYear"
                            disabled
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label for="deal-vin-input" class="form-label"
                            >VIN или серийный номер</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-vin-input"
                            placeholder="VIN или серийный номер"
                            aria-label="VIN или серийный номер"
                            aria-describedby="deal-vin-input"
                            v-model="leadVin"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="card-info-title mt-4">Финансовая информация</p>

                  <div class="border border-1 ceiled-2 rounded-1">
                    <div class="card-body">
                      <div class="row g-3 mb-4">
                        <div class="col-12 col-md-6">
                          <label for="deal-price-input" class="form-label"
                            >Стоимость с НДС</label
                          >
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              id="deal-price-input"
                              placeholder="Стоимость с НДС"
                              aria-label="Стоимость с НДС"
                              aria-describedby="deal-price-input"
                              v-model="leadPriceFormatted"
                              disabled
                            />
                            <span class="input-group-text">{{
                              leadCurrency
                            }}</span>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-md-6">
                          <label for="deal-advance-input" class="form-label"
                            >Аванс</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-advance-input"
                            placeholder="Аванс"
                            aria-label="Аванс"
                            aria-describedby="deal-advance-input"
                          />
                        </div> -->
                      </div>
                      <!-- <div class="row g-3 mb-4">
                        <div class="col-12 col-md-6">
                          <label for="deal-purchase-input" class="form-label"
                            >Срок лизинга</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-purchase-input"
                            placeholder="Способ покупки"
                            aria-label="Способ покупки"
                            aria-describedby="deal-purchase-input"
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label for="deal-payment-input" class="form-label"
                            >График платежей</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-payment-input"
                            placeholder="График платежей"
                            aria-label="График платежей"
                            aria-describedby="deal-payment-input"
                          />
                        </div>
                      </div>
                      <div class="row g-3 mb-4">
                        <div class="col-12 col-md-6">
                          <label for="deal-rate-input" class="form-label"
                            >Процентная ставка</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-rate-input"
                            placeholder="Процентная ставка"
                            aria-label="Процентная ставка"
                            aria-describedby="deal-rate-input"
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label for="deal-residual-input" class="form-label"
                            >Остаточная стоимость объекта</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="deal-residual-input"
                            placeholder="Остаточная стоимость объекта"
                            aria-label="Остаточная стоимость объекта"
                            aria-describedby="deal-residual-input"
                          />
                        </div>
                      </div> -->
                    </div>
                  </div>

                  <div class="border border-1 ceiled-2 mt-4 rounded-1">
                    <div class="card-body">
                      <div class="row g-3 mb-4">
                        <div class="col-12 col-md-6">
                          <label for="deal-commission-input" class="form-label"
                            >Агентское вознаграждение*</label
                          >
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              id="deal-commission-input"
                              placeholder="Агентское вознаграждение*"
                              aria-label="Агентское вознаграждение*"
                              aria-describedby="deal-commission-input"
                              v-model="formattedCommission"
                              @input="formatCommission"
                              disabled="true"
                            />
                            <span class="input-group-text">{{
                              leadCurrency
                            }}</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <label for="deal-percentage-input" class="form-label"
                            >Процент (%) от сделки</label
                          >
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              id="deal-percentage-input"
                              placeholder="Процент (%) от сделки"
                              aria-label="Процент (%) от сделки"
                              aria-describedby="deal-percentage-input"
                              v-model="formattedPercentage"
                              :disabled="true"
                              @input="handlePercentageInput"
                              @blur="handlePercentageBlur"
                            />
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>

                      <!-- primary button -->
                      <template v-if="deal.status !== 'rejected'">
                        <button
                          v-if="
                            commisionStatus !== 'pending' &&
                            commisionStatus !== 'accepted' &&
                            commisionStatus !== 'rejected'
                          "
                          class="btn btn-gray"
                          @click="handleSetCommission"
                          :disabled="true"
                        >
                          Согласовать
                        </button>
                        <button
                          v-else-if="commisionStatus === 'pending'"
                          class="btn btn-gray"
                          disabled
                          @click="handleSetCommission"
                        >
                          Ожидание
                        </button>
                        <button
                          v-else-if="commisionStatus === 'accepted'"
                          class="btn btn-primary"
                          :style="{
                            backgroundColor: '#D0EDE2',
                            borderColor: '#D0EDE2',
                            color: '#057A4D',
                          }"
                          disabled
                        >
                          На одобрении
                        </button>
                        <button v-else class="btn btn-gray" disabled>
                          Отклонено
                        </button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-2">
                <div class="deal-chat-wrapper main-chat-blk">
                  <chat-content :chatData="chatData"></chat-content>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-body">
                  <div class="card-info-title-wrapper">
                    <p class="card-info-title">Документы</p>
                    <form v-if="deal.status !== 'rejected'" action="">
                      <button class="btn btn-primary gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M6.49992 0.5V12.5"
                            stroke="white"
                            stroke-linecap="ceil"
                            stroke-linejoin="ceil"
                          />
                          <path
                            d="M12.5 6.49968H0.5"
                            stroke="white"
                            stroke-linecap="ceil"
                            stroke-linejoin="ceil"
                          /></svg
                        >Загрузить
                      </button>
                    </form>
                  </div>

                  <div class="files-list">
                    <div class="message-empty" v-if="files.length === 0">
                      <p>Здесь будут отображаться документы по сделке.</p>
                    </div>
                    <div
                      v-else
                      class="file-wrapper"
                      v-for="file in files"
                      :key="file.name"
                    >
                      <div class="file-description">
                        <h5>{{ file.name }}</h5>
                        <small>{{ file.date }}</small>
                      </div>
                      <div class="file-controls">
                        <button class="btn file-download-btn">
                          <i class="ti ti-download"></i>
                        </button>
                        <div class="dropdown">
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            class="dropset"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </a>
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Details</a
                              >
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Share</a
                              >
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Copy</a
                              >
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Move</a
                              >
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Download</a
                              >
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Rename</a
                              >
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Archeived</a
                              >
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                >Delete</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-body">
                  <div class="card-info-title-wrapper">
                    <p class="card-info-title">Журнал действий</p>
                  </div>
                  <div
                    class="message-empty"
                    v-if="groupedActivities.length === 0"
                  >
                    <p>Журнал действий будет доступен 22/12/2024</p>
                  </div>
                  <div v-else class="notification-wrapper">
                    <div class="noti-content">
                      <template
                        v-for="(activities, date) in groupedActivities"
                        :key="date"
                      >
                        <!-- Date Badge -->
                        <div class="badge badge-status badge-accepted mb-3">
                          {{ date }}
                        </div>

                        <!-- Notification List -->
                        <ul class="notification-list">
                          <li
                            class="notification-message"
                            v-for="item in activities"
                            :key="item.id"
                          >
                            <div class="notification-item">
                              <div class="media d-flex">
                                <span
                                  class="avatar flex-shrink-0 badge-notification"
                                >
                                  <i
                                    v-if="item.description.includes('rejected')"
                                    class="ti ti-x"
                                  ></i>

                                  <i
                                    v-else-if="
                                      item.description.includes('accepted')
                                    "
                                    class="ti ti-check"
                                  ></i>
                                  <i v-else class="ti ti-bell"> </i>
                                </span>
                                <div class="media-body flex-grow-1">
                                  <p class="noti-details">
                                    {{
                                      item.description
                                        .replace("rejected", `"Отклонена"`)
                                        .replace("accepted", `"Принята"`)
                                        .replace("reserved", `"Забронирована"`)
                                        .replace("finished", `"Завершена"`)
                                    }}
                                  </p>
                                  <p class="noti-time">
                                    {{ formatDate(item.updated_at) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main-footer></main-footer>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <!-- Delete Campaign -->
  <div class="modal fade" id="delete_campaign" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="rejectModalCloseEl"
              class="modal-btn-close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M19.7913 5.2085L5.20801 19.7918"
                  stroke="#001615"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.7913 19.7918L5.20801 5.2085"
                  stroke="#001615"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <h4 class="mb-2">Укажите причину отказа</h4>
            <p class="mb-4">
              Пожалуйста, укажите причину отказа, чтобы мы могли зафиксировать
              это в системе.
            </p>

            <div class="dropdown dropdown-gray mb-2 w-100">
              <a
                href="#"
                ref="rejectReasonDropdownEl"
                class="text-dark d-inline-flex align-items-center justify-content-between gap-2 w-100"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <template v-if="rejectReason">
                  {{ rejectReason }}
                </template>
                <template v-else> Выберите из списка </template>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                >
                  <path
                    d="M1.36426 1L6.86426 6L12.3643 1"
                    stroke="#000000"
                    stroke-linecap="ceil"
                    stroke-linejoin="ceil"
                  />
                </svg>
              </a>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  href="javascript:void(0);"
                  @click.stop="
                    handleRejectReason('Отсутствие необходимых документов')
                  "
                  ><span>Отсутствие необходимых документов</span></a
                >
                <a
                  class="dropdown-item"
                  href="javascript:void(0);"
                  @click.stop="handleRejectReason('Несоответствие требованиям')"
                  ><span>Несоответствие требованиям</span></a
                ><a
                  class="dropdown-item"
                  href="javascript:void(0);"
                  @click.stop="
                    handleRejectReason('Недостаточная платежеспособность')
                  "
                  ><span>Недостаточная платежеспособность</span></a
                >
              </div>
            </div>
            <textarea
              class="form-control"
              :style="{ resize: 'none', padding: '10px 15px' }"
              rows="4"
              placeholder="Комментарий*"
              v-model="rejectReasonComment"
            ></textarea>
            <div class="d-flex align-items-center justify-content-center mt-4">
              <a href="#" class="btn btn-primary w-100" @click="handleReject"
                >Отправить</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Campaign -->
</template>

<script setup>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { useRoute, useRouter } from "vue-router";
import { watch, ref, computed } from "vue";
import moment from "moment";
import { useDealStore } from "@/stores/deal";
import avatar2 from "@/assets/img/profiles/avatar-02.jpg";
import { useRequestsStore } from "@/stores/requests";
import { useToast } from "vue-toast-notification";
import { useAuthStore } from "@/stores/auth";

const toast = useToast();
const requestsStore = useRequestsStore();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const title = ref(`Сделка № ${route.params.id.slice(0, 4)}`);
const breadcrumb = ref([
  {
    title: "Сделки",
    link: "/deals",
  },
  {
    title: `Сделка № ${route.params.id.slice(0, 4)}`,
    link: `/dealsa/${route.params.id}`,
  },
]);

function formatDayMM(date) {
  return moment(date).locale("ru").format("DD MMM");
}

function formatDate(date) {
  return moment(date).locale("ru").format("DD MMM YYYY, HH:mm");
}

const rejectModalCloseEl = ref(null);
const rejectReasonDropdownEl = ref(null);
const rejectReason = ref(null);
const rejectReasonComment = ref(null);
const handleRejectReason = (reason) => {
  rejectReason.value = reason;
  rejectReasonDropdownEl.value.click();
};

const handleReject = async (e) => {
  if (!rejectReason.value || !rejectReasonComment.value) {
    e.stopPropagation();
    e.preventDefault();
    toast.error("Пожалуйста, укажите причину отказа");
    return;
  }
  const data = {
    leadId: route.params.id,
    managerId: authStore.getCurrentManagerID,
    comment: rejectReasonComment.value,
  };
  rejectModalCloseEl.value.click();
  try {
    const response = await requestsStore.rejectRequest(data);
    if (response.status === 201 && response.data.status === "rejected") {
      await requestsStore.fetchRequests();
      await authStore.fetchActivities();
      router.push("/requests");
      toast.success("Сделка отклонена");
    } else {
      toast.error("Произошла ошибка при отклонении сделки");
    }
  } catch (error) {
    console.log(error);
    toast.error("Произошла ошибка при отклонении сделки");
  }
};

// New Computed Property to Group Activities by Date
const groupedActivities = computed(() => {
  const allActivities = [...dealStore.getActivities].sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  );

  return allActivities.reduce((groups, activity) => {
    const date = formatDayMM(activity.updated_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(activity);
    return groups;
  }, {});
});

const handleFinish = async () => {
  try {
    const response = await requestsStore.finishRequest({
      leadId: route.params.id,
      managerId: authStore.getCurrentManagerID,
    });

    if (response.status === 201 && response.data.status === "finished") {
      await requestsStore.fetchRequests();
      await authStore.fetchActivities();
      router.push("/deals");
      toast.success("Сделка завершена");
    } else {
      toast.error("Произошла ошибка при завершении сделки");
    }
  } catch (error) {
    console.log(error);
    toast.error("Произошла ошибка при завершении сделки");
  }
};

const files = ref([
  // {
  //   name: "Договор между ООО «Наименование» и ООО “Наименование” на предоставление в лизинг ТС Mercedes-Benz.",
  //   date: "17 Окт 2024",
  // },
  // {
  //   name: "Счёт на оплату №567 от 10.10.2024",
  //   date: "17 Окт 2024",
  // },
  // {
  //   name: "Счёт-фактура №345 от 20.10.2024",
  //   date: "17 Окт 2024",
  // },
]);

const dealStore = useDealStore();

const deal = computed(() => dealStore.getDeal);
// const createdAt = computed(() => formatDate(deal.value.created_at));

const leadType = computed(() => deal.value?.lead_type || "н/a");
const leadBrand = computed(() => deal.value?.fr_brand?.name || "н/a");
const leadModel = computed(() => deal.value?.fr_model?.name || "н/a");
const leadVin = computed(() => deal.value?.fr_vin || "н/a");
const leadYear = computed(() => deal.value?.fr_year || "н/a");
const leadPrice = computed(() => deal.value?.fr_object_price);
const leadPriceFormatted = computed(() => formatPriceNumber(leadPrice.value));
const leadCurrency = computed(() => deal.value?.doc_currency?.name || "");
const leadPurchaseMethod = computed(
  () => deal.value?.fr_purchase_method || "н/a"
);
const leadCommissionRaw = ref(deal.value?.commission_amount || 0);
const formattedCommission = computed(() =>
  formatPriceNumber(leadCommissionRaw.value)
);

const leadPercentageRaw = computed(() =>
  leadPrice.value ? (leadCommissionRaw.value / leadPrice.value) * 100 : 0
);

const formattedPercentage = computed(() => {
  const percentage = truncateToTwoDecimals(leadPercentageRaw.value);
  return `${percentage}`;
});
const userPercentageInput = ref("");

const chatData = ref({
  title: `Сделка № ${route.params.id.slice(0, 4)}`,
  avatar: avatar2,
});

watch(
  () => leadPercentageRaw.value,
  (newVal) => {
    userPercentageInput.value = newVal;
  },
  { immediate: true }
);

// Watcher to initialize both leadCommission and leadPercentage when deal data is loaded
watch(
  () => deal.value?.commission_amount,
  (newCommission) => {
    leadCommissionRaw.value = newCommission;
    // No need to set leadPercentage here as it's computed based on leadCommission
  },
  { immediate: true }
);

// Optional: If you want to watch leadPrice changes to update leadPercentage accordingly
watch(leadPrice, (newPrice) => {
  if (newPrice) {
    // leadPercentage.value = (leadCommissionRaw.value / newPrice) * 100;
  }
});

const truncateToTwoDecimals = (num) => {
  const numStr = num.toString();
  const decimalIndex = numStr.indexOf(".");
  if (decimalIndex === -1) return numStr;
  return numStr.substring(0, decimalIndex + 3);
};

function formatPriceNumber(value) {
  if (!value) return 0;
  return new Intl.NumberFormat("ru-RU").format(value);
}

function formatCommission(event) {
  const value = event.target.value.replace(/\s/g, "");
  const numericValue = parseInt(value, 10);
  if (commisionStatus.value === "rejected") {
    commisionStatus.value = null;
  }
  if (!isNaN(numericValue)) {
    leadCommissionRaw.value = numericValue;
    formattedCommission.value = formatPriceNumber(numericValue);
  } else {
    leadCommissionRaw.value = "";
    formattedCommission.value = "";
  }
}

const commisionStatus = ref(null);

const handleSetCommission = async () => {
  try {
    const response = await dealStore.setCommission(route.params.id, {
      commission: +leadCommissionRaw.value,
      managerId: +authStore.getCurrentManagerID,
    });
    if (response.status === 201) {
      commisionStatus.value = "pending";
      await dealStore.fetchDeal(route.params.id);
      toast.success("Комиссия отправлена на согласование");
    } else {
      toast.error("Произошла ошибка при отправке комиссии на согласование");
    }
  } catch (error) {
    console.log(error);
    toast.error("Произошла ошибка при отправке комиссии на согласование");
  }
};

const handlePercentageInput = (event) => {
  const value = event.target.value.replace(/[^0-9.]/g, ""); // Разрешаем только цифры и точку
  userPercentageInput.value = value;

  let numericValue = parseFloat(value);

  if (isNaN(numericValue)) {
    numericValue = 0;
  } else {
    // Ограничиваем значение между 0 и 100
    numericValue = Math.min(Math.max(numericValue, 0), 100);
  }

  if (commisionStatus.value === "rejected") {
    commisionStatus.value = null;
  }

  if (leadPrice.value && numericValue !== "") {
    leadCommissionRaw.value = (numericValue / 100) * leadPrice.value;
  } else {
    leadCommissionRaw.value = 0;
  }
};

const handlePercentageBlur = () => {
  if (userPercentageInput.value === "") {
    userPercentageInput.value = "0.00";
    leadCommissionRaw.value = 0;
    return;
  }

  let numericValue = parseFloat(userPercentageInput.value);

  if (isNaN(numericValue)) {
    numericValue = 0;
  }

  // Ограничиваем значение между 0 и 100
  numericValue = Math.min(Math.max(numericValue, 0), 100);

  // Обрезаем до двух знаков без округления
  userPercentageInput.value = truncateToTwoDecimals(numericValue).toString();
  leadCommissionRaw.value = (numericValue / 100) * leadPrice.value;
};

const fetchDealData = async () => {
  await dealStore.fetchDeal(route.params.id);
  chatData.value.mangerId = authStore.getCurrentManagerID;
  await dealStore.fetchActivities(route.params.id);
  try {
    const response = await dealStore.getCommission(
      route.params.id,
      authStore.getCurrentManagerID
    );
    // leadCommission.value = response.data.commission;
    commisionStatus.value = response.data[0]?.approval?.status;
    leadCommissionRaw.value =
      response.data[0]?.approval?.requested_amount ||
      response.data[0]?.commission_ammount;
  } catch (error) {
    console.log(error);
  }
};

fetchDealData();

watch(
  () => route.params.id,
  (newId, oldId) => {
    if (newId !== oldId) {
      title.value = `Сделка № ${newId.slice(0, 4)}`;
      breadcrumb.value = [
        {
          title: "Сделки",
          link: "/deals",
        },
        {
          title: `Сделка № ${newId.slice(0, 4)}`,
          link: `/deals/${newId}`,
        },
      ];
      fetchDealData();
    }
  }
);

// Дополнительный Watcher для отслеживания любых изменений в маршруте
watch(
  () => route.fullPath,
  () => {
    fetchDealData();
  }
);
</script>
