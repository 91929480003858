<template>
  <a-table
    class="stripped table-hover"
    :columns="columns"
    :data-source="filteredData"
    :row-selection="rowSelection"
    :pagination="paginationConfig"
    description="Нет данных"
    :customRow="
      (record) => {
        return {
          onClick: (event) => {
            handleRowClick(record);
          },
        };
      }
    "
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'ID'">
        <div>
          <div class="fs-12 fw-normal text-black">{{ record.ID }}</div>
        </div>
      </template>
      <template v-else-if="column.key === 'Created_at'">
        <div class="fs-12 fw-normal text-black">
          {{ formatDate(record.Created_at) }}
        </div>
      </template>
      <template v-else-if="column.key === 'Deal_Type'">
        <td>
          <span :class="record.TypeClass">{{ record.Deal_Type }}</span>
        </td>
      </template>
      <template v-else-if="column.key === 'INN'">
        <div class="fs-12 fw-normal text-black">{{ record.INN }}</div>
      </template>
      <template v-else-if="column.key === 'Deal_Status'">
        <td class="d-flex w-100">
          <span :class="record.StatusClass"
            >{{
              record.Deal_Status === "accepted"
                ? "На одобрении"
                : record.Deal_Status === "reserved"
                ? "Забронировано"
                : record.Deal_Status === "rejected"
                ? "Отклонено"
                : record.Deal_Status === "finished"
                ? "Реализовано"
                : ""
            }}
          </span>
        </td>
      </template>
      <template v-else-if="column.key === 'Date'">
        <div class="fs-12 fw-normal text-black">
          {{ formatDate(record.Date) }}
        </div>
      </template>
      <template v-else-if="column.key === 'Deal_comment'">
        <div class="fs-12 fw-normal badge badge-status badge-comment">
          {{ record.Deal_comment }}
        </div>
      </template>
      <!-- <template v-else-if="column.key === 'action'">
        <div class="dropdown table-action">
          <a
            href="javascript:;"
            class="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="(e) => e.stopPropagation()"
            ><i class="fa fa-ellipsis-v"></i
          ></a>
          <div class="dropdown-menu dropdown-menu-right" style="">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#delete_deal"
              ><i class="ti ti-trash text-danger"></i> Удалить</a
            >
          </div>
        </div>
      </template> -->
    </template>
  </a-table>
</template>

<script setup>
// import { useDealsStore } from "@/stores/deals";
import moment from "moment";
import { onBeforeMount, onMounted, computed, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useRequestsStore } from "@/stores/requests";
import { useAuthStore } from "@/stores/auth";

const requestsStore = useRequestsStore();
const authStore = useAuthStore();
const router = useRouter();
const props = defineProps({
  filter: {
    type: String,
    default: "all",
  },
});

// const dealsStore = useDealsStore();
const formatDate = (date) => {
  return moment(date).locale("ru").format("DD MMM YYYY");
};

const paginationConfig = {
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "30"],
  showTotal: (total, range) => `Показано ${range[0]}-${range[1]} из ${total}`,
  locale: {
    items_per_page: "элементов на странице",
  },
};
const data = ref([]);
const columns = [
  {
    title: "ID",
    dataIndex: "ID",
    key: "ID",
    sorter: {
      compare: (a, b) => {
        a = a.ID.toLowerCase();
        b = b.ID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Дата создания",
    dataIndex: "Created_at",
    key: "Created_at",
    sorter: {
      compare: (a, b) => {
        return new Date(a.Created_at) - new Date(b.Created_at);
      },
    },
  },
  {
    title: "Тип сделки",
    dataIndex: "Deal_Type",
    key: "Deal_Type",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Type.toLowerCase();
        b = b.Deal_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "ИНН",
    dataIndex: "INN",
    key: "INN",
    sorter: {
      compare: (a, b) => {
        a = a.INN.toLowerCase();
        b = b.INN.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Статус",
    dataIndex: "Deal_Status",
    key: "Deal_Status",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Status.toLowerCase();
        b = b.Deal_Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Дата",
    dataIndex: "Date",
    key: "Date",
    sorter: {
      compare: (a, b) => {
        return new Date(a.Date) - new Date(b.Date);
      },
    },
  },
  {
    title: "Комментарий",
    dataIndex: "Deal_comment",
    key: "Deal_comment",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_comment?.trim().toLowerCase();
        b = b.Deal_comment?.trim().toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "",
  //   dataIndex: "Action",
  //   key: "action",
  //   sorter: false,
  // },
];
const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};
const filteredData = computed(() => {
  if (props.filter === "all") {
    return data.value;
  }
  return data.value.filter(
    (item) => item.Deal_Status.toLowerCase() === props.filter.toLowerCase()
  );
});

watch(
  () => props.filter,
  () => {
    console.log("update");
    updateTableData();
  }
);

const handleRowClick = (record) => {
  if (
    record.Deal_Status === "accepted" ||
    record.Deal_Status === "finished" ||
    record.Deal_Status === "reserved"
  ) {
    router.push(`/deals/${record.slug}`);
  }
};
const updateTableData = () => {
  data.value = requestsStore.getRequests.map((item) => {
    return {
      key: item.lead_id,
      ID: `№ ${item.lead_id.slice(0, 4)}`,
      slug: item.lead_id,
      Created_at: item.created_at,
      Deal_Type: "Лизинг",
      TypeClass: "badge badge-type",
      INN: item.inn,
      Deal_Status: item.status,
      StatusClass: `badge badge-status badge-${item.status}`,
      Date: item.sent_at,
      Deal_comment: item.comment,
    };
  });
};

watch(
  () => requestsStore.getRequests,
  () => {
    updateTableData();
  }
);

watch(
  () => authStore.getCurrentManagerID,
  async (newRequests) => {
    updateTableData();
  }
);

onMounted(() => {
  updateTableData();
  if (document.getElementsByClassName("edit-popup").length > 0) {
    var addPopups = document.getElementsByClassName("edit-popup");
    for (var i = 0; i < addPopups.length; i++) {
      addPopups[i].addEventListener("click", () => {
        var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
        if (togglePopup) {
          togglePopup.classList.add("sidebar-popup");
        }
      });
    }

    var sidebarCloses = document.getElementsByClassName("sidebar-close1");
    for (var j = 0; j < sidebarCloses.length; j++) {
      sidebarCloses[j].addEventListener("click", () => {
        var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
        if (togglePopup) {
          togglePopup.classList.remove("sidebar-popup");
        }
      });
    }
  }
});
</script>
